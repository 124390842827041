<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
/*import Multiselect from "vue-multiselect";
import { MazPhoneNumberInput } from "maz-ui";
import moment from "moment"; */
//import { format } from "date-fns";
//import { addYears, formatWithOptions } from 'date-fns/fp'
//import { eo } from 'date-fns/locale'
//import image from "@/assets/images/users/user.png";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Historiques des vidanges",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Suivi / Entretien",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Historistique",
          active: true,
        },
      ],
      
      transaction:{
        total:"",
        echouee:"",
        reussie:""
      },
       totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100,250,500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      ordersData: [
        {
          orderid: "BMW",
          date: "18 Janvier",
          prestataire: "Michellin",
          total: "25000 FCFA",
          reparation:"réparation climatisation",
          immatriculation: "BH 6808",
          ref: "Fac_205",
        },
        {
          orderid: "BMW",
          date: "15 Fevrier",
          prestataire: "Rim K Garage",
          total: "35000 FCFA",
          reparation:"Changer pneus",
          immatriculation: "BE 6245",
          ref: "Fac_458",
        },
        {
          orderid: "RANGE ROVER",
          date: "22 Aout",
          prestataire: "Ets Bléblé",
          total: "58000 FCFA",
          reparation:"Changer Moteur",
          immatriculation: "AL 6175",
          ref: "Fac_405",
        },
      ],
      fields: [
        { key: "orderid", sortable: true, label:"Modèle" },
        { key: "immatriculation", sortable: true, label:"Immatriculation" },
        { key: "prestataire", sortable: true, label: "Prestataire" },
        { key: "date", sortable: true, label: "Date de réparation" },
        { key: "reparation", sortable: true, label: "Liste de réparation" },
        { key: "total", sortable: true, label: "Coût" },
        { key: "date", sortable: true , label:"Référence Facture"},
        { key: "action", sortable: true , label:"Actions"},
      ],
      transactionData:[],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      showDelete: false,
      showEdit: false,
      showdisable: false,
    };
  },

  async mounted(){
    const historiqueTransac = await apiRequest(
      "GET",
      "admin/transaction/info",
      undefined,
      false
    );
    //console.log("historique:",historiqueTransac.data)
    if(historiqueTransac && historiqueTransac.data){

      this.transaction.total = historiqueTransac.data.total
        ? historiqueTransac.data.total
        : "0";
      this.transaction.echouee = historiqueTransac.data.echecs
        ? historiqueTransac.data.echecs
        : "0";
        this.transaction.reussie = historiqueTransac.data.reussies
        ? historiqueTransac.data.reussies
        : "0";
    }

    const listeTransaction= await apiRequest(
      "GET",
      "admin/transactions",
      undefined,
      false
    );

     if(listeTransaction && listeTransaction.data){
     //console.log("Liste:",listeTransaction.data)
     
     const TransactListTable = listeTransaction.data.map((transaction)=>{
       return{
         profil: transaction.client.personne.lastname+" "+transaction.client.personne.firstname,
         trajet: transaction.trajet.lieuDepart.nom+" >> "+transaction.trajet.lieuArrivee.nom,
         date: new Date(transaction.datePaiement).toLocaleString("fr-FR"),
         montant: transaction.trajet.prix,
         moyen: transaction.moyen,
         depart: new Date(transaction.trajet.dateHeureDepart).toLocaleString("fr-FR"),
         place: transaction.place,
         about: transaction.statut=="PAYER" ? "PAID" : transaction.statut
       };
     });
     this.transactionData = TransactListTable
    }
    
  },

  methods: {
    editClick(){
      this.showEdit = true;
      //this.infoUser.id = row.id;
    },
    DeleteClick(){
      this.showDelete = true;
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">              
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                      <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    class="table-centered"
                    :items="ordersData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                  >
                    <template v-slot:cell(paymentstatus)="row">
                      <div
                        class="badge font-size-12"
                        :class="{'badge-soft-danger': `${row.value}` === 'Chargeback',
                            'badge-soft-success': `${row.value}` === 'Paid',
                            'badge-soft-warning': `${row.value}` === 'Unpaid'}"
                      >{{ row.value}}</div>
                    </template>

                  <template v-slot:cell(about)="row">
                      <div
                        class="badge font-size-12"
                        :class="{
                          
                          'badge-soft-danger': `${row.value}` === 'ERROR' ? 'badge-soft-danger': `${row.value}` === 'FAILED',
                          'badge-soft-success': `${row.value}` === 'PAID',
                          'badge-soft-warning': `${row.value}` === 'INITIAL',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>

                    <template v-slot:cell(invoice)>
                      <button class="btn btn-light btn-rounded">
                        Invoice
                        <i class="mdi mdi-download ml-2"></i>
                      </button>
                    </template>
                    <template v-slot:cell(action)>
                      <a
                        href="javascript:void(0);"
                        class="text-primary"
                        v-b-tooltip.hover
                        @click="editClick()"
                        title="Editer"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="text-danger"
                        v-b-tooltip.hover
                        @click="DeleteClick()"
                        title="Supprimer"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-right">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div> 
    <b-modal
      id="modal-center"
      centered
      size="xl"
      v-model="showEdit"
      title="Suivi/Entretien"
      title-class="font-18"
      hide-footer
    >
      <form
        class="form-horizontal"
        role="form"
        method="post"
        @submit.prevent="EditSubmit"
      >
        <div class="row">
          <div class="col-md-6">
            <label for="">Immatriculation</label>
            <input type="text" class="form-control" v-model="marque" id="" />
          </div>
          <div class="col-md-6">
            <label for="">Prestataire</label>
            <input type="text" class="form-control" v-model="model" id="" />
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-6">
            <label for="">Date de réparation</label>
            <input type="text" class="form-control" v-model="carte" id="" />
          </div>
          <div class="col-md-6">
            <label for="">Liste de réparation</label>
            <textarea class="form-control"></textarea>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-md-6">
            <label for="">Coût</label>
            <input
              type="text"
              class="form-control"
              v-model="motorisation"
              id=""
            />
          </div>
          <div class="col-md-6">
            <label for="">Référence facture</label>
            <input
              type="text"
              class="form-control"
              v-model="immatriculation"
              id=""
            />
          </div>
        </div>
        <button class="btn btn-success float-right mt-4" type="submit">
          valider
        </button>
      </form>
    </b-modal> 
    <b-modal
      id="modal-backdrop"
      centered
      v-model="showDelete"
      title="Voulez-vous vraiment supprimer ?"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showDelete = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="desactiveUser"
          >Oui</b-button
        >
      </div>
    </b-modal>    
  </Layout>
</template>

<style>

</style>
